/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"

function Seo({description, lang, meta, title, image}) {
    const {site} = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    image
                    siteUrl
                }
            }
        }
    `)

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    const defaultImage = `${site.siteMetadata?.siteUrl}${image || site.siteMetadata?.image}`
    const schema = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": defaultTitle,
        "url": site.siteMetadata?.siteUrl,
        "sameAs": [
            "https://twitter.com/vineber_app",
            "https://www.facebook.com/vineber_app",
            "https://www.instagram.com/accounts/vineber_app",
            "https://discord.gg/7VrnFnVK7z",
        ],
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:site_name`,
                    content: title,
                },
                {
                    property: `og:url`,
                    content: site.siteMetadata?.siteUrl,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: defaultImage,
                },
                {
                    property: `og:image:secure_url`,
                    content: defaultImage,
                },
                {
                    property: `og:image:type`,
                    content: "image/jpeg",
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: defaultImage,
                },
                {
                    name: `twitter:site`,
                    content: site.siteMetadata?.siteUrl,
                },
            ].concat(meta)}
        >
            <script type="application/ld+json">
                {JSON.stringify(schema)}
            </script>
        </Helmet>
    )
}

Seo.defaultProps = {
    lang: `es`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default Seo
